function mapsSelector(location) {
  console.log("MAP");
  if /* if we're on iOS, open in Apple Maps */
    ((navigator.platform.indexOf("iPhone") != -1) ||
     (navigator.platform.indexOf("iPad") != -1) ||
     (navigator.platform.indexOf("iPod") != -1))
    window.open("maps://" + location);
  else /* else use Google */
    window.open("https://" + location);
}


function highlightToday() {
  var d = new Date();
  var n = d.getDay();
  $('.location .hours>.column').eq(n).addClass('today');
}



$(function() {
  // mapsSelector();
  highlightToday();
});
